import 'assets/styles/Steps_of_Booking.css';
import { AiOutlineLogin, AiOutlineCheckCircle} from "react-icons/ai";
import { BiSolidUserDetail } from "react-icons/bi";

function Steps_of_Booking() {
  return (
    <section className="booking">
      <h2>
        3 Step Booking
      </h2>
      <div className="belowtext"></div>
      <div className="steps">
        <div><AiOutlineLogin style={{fontSize: 45}}/><p>Login / Register</p></div>
        <div><BiSolidUserDetail style={{fontSize: 45}}/><p>Enter your Details</p></div>
        <div><AiOutlineCheckCircle style={{fontSize: 45}}/><p>Get Quote & Book</p></div>
      </div>
    </section>
  );
}

export default Steps_of_Booking;
