import "assets/styles/login.css";
import React, { useEffect, useState } from "react";
import OtpInput from "otp-input-react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { auth } from "backend/firebase.config";
import { Toaster, toast } from "react-hot-toast";
import image from "assets/images/login.jpg";

function Login() {
    const [ph, setPh] = useState('');
    const [otp, setOtp] = useState('');
    const [user, setUser] = useState(null);
    function onCaptchaVerify() {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                'size': 'invisible',
                'callback': (response) => { onSignUp() },
                'expired-callback': () => { }
            });
        }
    }

    function onSignUp() {
        onCaptchaVerify()
        const appVerifier = window.recaptchaVerifier
        const formatPh = "+" + ph;
        signInWithPhoneNumber(auth, formatPh, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                toast.success('OTP sent successfully!')
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function onOTPVerify() {
        window.confirmationResult.confirm(otp).then(async (res) => {
            setUser(res.user);
        })
            .catch((err) => {
                console.log(err);
            })
    }
    return (
        <section className="login-start">
            <img className="login-img" src={image} alt />
            <div className="login">
                <Toaster toastOptions={{ duration: 1000 }} />
                <div id="recaptcha-container"></div>
                {user ? (
                    <div className="success">
                        <h2 className="text-center text-white font-medium text-2xl">
                            Login Success
                        </h2>
                    </div>
                ) : (
                    <></>
                )}
                <div className="form">
                    <h2>Login</h2>
                    <PhoneInput className="phonein" country={"in"} value={ph} onChange={setPh}></PhoneInput>
                    <p onClick={onSignUp}>Generate OTP</p>
                    <OtpInput className="phonein" value={otp} onChange={setOtp} OTPLength={6} otpType="number" disabled={false} autoFocus></OtpInput>
                    <button onClick={onOTPVerify} id="btn"><span>Login</span></button>
                    <button id="google">Continue of Google</button>
                    {/*<button id="facebook">Continue of Facebook</button> */}
                </div>
            </div>
        </section>
    );
}

export default Login;
