import './Steps_of_Booking';
import delhi from "assets/images/cities_images/delhi.png";
import mumbai from "assets/images/cities_images/mumbai.png";
import jaipur from "assets/images/cities_images/jaipur.png";
import surat from "assets/images/cities_images/surat.png";

function Cities() {
    return (
        <section className="cities">
            <h2>
                Cities
            </h2>
            <div className="belowtext"></div>
            <div className="cities-steps">
                <div><img src={delhi} alt="Delhi" /><p>Delhi</p></div>
                <div><img src={mumbai} alt="Mumbai" /><p>Mumbai</p></div>
                <div><img src={jaipur} alt="Jaipur" /><p>Jaipur</p></div>
                <div><img src={surat} alt="Surat" /><p>Surat</p></div>
            </div>
        </section>
    );
}

export default Cities;
